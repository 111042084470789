import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { emojisplosion } from "emojisplosion";
import { isSameDay, parseISO, format } from "date-fns";
import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";

import { Videoplayer } from "@openabos/react-circular-player";
import "@openabos/react-circular-player/dist/cjs/index.css";
import "../../styles/react-circular-player-new-styles.css";
import "../../styles/react-three-dots-elastic.css";

import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  ExpandMoreOutlined,
  FilterTiltShiftOutlined,
  FormatListBulletedOutlined,
  GetApp,
  MissedVideoCallRounded,
  NotInterestedOutlined,
  PhoneMissedRounded,
  RecordVoiceOverRounded,
  ReplyOutlined,
  SentimentSatisfiedAltOutlined,
  ShareOutlined,
  StarOutlined,
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import extractDayMonthYearFromStringDateTime from "../../utils/extractDayMonthYearFromStringDateTime";
import getParentNodeByClassName from "../../utils/getParentNodeByClassName";
import toastError from "../../errors/toastError";

import AdsMessage from "../AdsMessage";
import Audio from "../Audio";
import ButtonPreview from "../ButtonPreview";
import ConfirmationModal from "../ConfirmationModal";
import EventPreview from "../EventPreview";
import EventPreviewQuotedMessage from "../EventPreviewQuotedMessage";
import GroupInvitePreview from "../GroupInvitePreview";
import ListPreview from "../ListPreview";
import LocationPreview from "../LocationPreview";
import MessageBodyWrapper from "../MessageBodyWrapper";
import MessageEditModal from "../MessageEditModal";
import MessageEditHistoryModal from "../MessageEditHistoryModal";
import MessagePropertiesModal from "../MessagePropertiesModal";
import MessageOptionsMenu from "../MessageOptionsMenu";
import MultiVcardPreview from "../MultiVcardPreview";
import ModalImageCors from "../ModalImageCors";
import PhoneNumberMessageOptionsMenu from "../PhoneNumberMessageOptionsMenu";
import PollPreview from "../PollPreview";
import PollPreviewQuotedMessage from "../PollPreviewQuotedMessage";
import PollVotesModal from "../PollVotesModal";
import VcardPreview from "../VcardPreview";
import ViewOnceMessage from "../ViewOnceMessage";
import ViewOnceRepliedMessage from "../ViewOnceRepliedMessage";

import disableDragEvent from "../../utils/disableDragEvent";
import saveFile from "../../utils/saveFile";
import useWhatsApps from "../../hooks/useWhatsApps";

import SocketMessagesList from "../../context/Socket/Listeners/SocketMessagesList";

const useStyles = makeStyles((theme) => ({
  quotedMessageAnchor: { textDecoration: "none", color: theme.palette.text.primary, },

  scrollBottomContainer: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    position: "absolute",
    right: "1em",
    bottom: "1em",
    zIndex: "9",
    opacity: "0.80",
    cursor: "pointer",
    padding: "5px",
    borderRadius: "7px",
    transition: "all 0.3s ease",
    "&:hover": { opacity: "1.00", },
  },

  scrollBottomButton: {
    color: theme.palette.text.primary,
    padding: "0px",
  },

  contactAvatarContainer: { height: "100%", paddingTop: "1em", },

  messageNotSentAlertContainer: {
    height: "100%",
    paddingTop: "1em",
    paddingLeft: "1em",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  messageNotSentAlertButton: { fontSize: "1.75em", cursor: "pointer", "&:hover": { opacity: "0.8", }, },

  contactAvatar: {
    width: "30px",
    height: "30px",
    marginRight: "10px",
    marginLeft: "10px",
    cursor: "pointer",
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  },

  callWrapper: { display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", },
  missedCall: { color: "red", fontSize: "18px", },
  horizontalFlip: { rotate: "y 180deg", fontSize: "20px", },
  forwardedMessage: { fontSize: "12px", color: "#998", display: "flex", alignItems: "center", gap: "5px", },
  buttonListFooter: { fontSize: "12px", color: theme.palette.text.primary, },
  messagesListWrapper: { padding: "5px 0 5px 0", overflow: "hidden", position: "relative", display: "flex", flexDirection: "column", flexGrow: 1, },

  messagesList: {
    borderRadius: "20px",
    backgroundImage: theme.backgroundImage,
    display: "flex",
    flexDirection: "column",

    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    ...theme.scrollbarStyles,

    [theme.breakpoints.down("sm")]: { paddingBottom: "90px", },
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  infoMessageContainer: { display: "flex", flexDirection: "column", gap: "10px", position: "relative", },

  reactionButton: {
    opacity: 0.65,
    display: "block",
    visibility: "hidden",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: { visibility: "visible" },
    "&:hover": { opacity: 1 },
  },

  reactionOptions: {
    display: "none",
    position: "absolute",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "center",

    gap: "5px",
    borderRadius: "15px",
    backgroundColor: theme.palette.background.paper,
    padding: "7px",
    boxShadow: "0 1px 1px #b3b3b3",
    width: "300px",
    zIndex: 9,

    [theme.breakpoints.down("sm")]: { gap: "2px", padding: "2px", width: "250px", },
  },

  // Arrow
  '@keyframes moveUpDown': { "0%": { rotate: "z 3deg" }, "100%": { rotate: "z -3deg" }, },

  reactionOption: {
    cursor: "pointer",
    fontSize: "20px",
    transition: 'all 0.3s',
    "&:hover": { transform: 'translateY(-5px)', animation: '$moveUpDown 0.3s infinite alternate', },
    [theme.breakpoints.down("sm")]: { fontSize: "18px" },
  },

  reactionOptionOpacity: { position: "relative", bottom: "-5px", opacity: "0.8", },

  reactionList: {
    position: "absolute",
    bottom: "-18px",
    display: "flex",
    flexDirection: "row",
    zIndex: "9",
    gap: "2px",
  },

  reactionListSticker: {
    position: "absolute",
    bottom: "0px",
    left: "5px",
    display: "flex",
    flexDirection: "row",
    zIndex: "9",
    gap: "2px",
  },

  reactionSpan: {
    borderRadius: "20px",
    padding: "3px 3px 3px 3px",
    backgroundColor: theme.palette.background.paper,
    fontSize: "15px",
    userSelect: "none",
    "&:hover": { filter: "brightness(0.92)" },
  },

  containerMessage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
    "&:hover #reactionButton": { visibility: "visible" },
    "&:hover #speechButton": { visibility: "visible" },
    "&:hover #shareButton": { visibility: "visible" },
    "&:target": { animation: '$targetMessage 2s linear', borderRadius: "5px" },
  },

  '@keyframes targetMessage': {
    "0%": { backgroundColor: "transparent", },
    "50%": { backgroundColor: theme.palette.primary.rgbaPastel, },
    "100%": { backgroundColor: "transparent", },
  },

  containerMessageLeft: { justifyContent: "left", marginLeft: "-15px", },
  containerMessageRight: { justifyContent: "right", marginRight: "-15px", },

  messageLeftSticker: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      visibility: "visible",
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    color: theme.palette.text.primary,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      visibility: "visible",
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: theme.palette.background.quotedPaper,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: { flex: "none", width: "4px", backgroundColor: "#6bcbef", },

  messageRightSticker: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      visibility: "visible",
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      visibility: "visible",
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.primary.messageBox,
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  internalMessage: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      visibility: "visible",
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.internalMessage,
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: theme.palette.primary.quotedMessageBox,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: { padding: 10, maxWidth: 300, height: "auto", whiteSpace: "pre-wrap", },
  quotedSideColorRight: { flex: "none", width: "4px", backgroundColor: "#35cd96", },

  messageActionsButton: {
    display: "flex",
    visibility: "hidden",
    position: "absolute",
    top: 0, right: 0,
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: { display: "flex", color: "#6bcbef", fontWeight: 500, },
  textContentItem: { overflowWrap: "break-word", },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    transition: "all 0.30s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  messageMediaFullScreen: {
    transition: "all 0.30s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  deletedMediaMessage: { opacity: 0.7, filter: "grayscale(80%)", },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: theme.palette.text.primary,
    userSelect: "none",
    
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "2px",
  },

  editedMessage: {
    fontStyle: "italic",
    cursor: "pointer",
    "&:hover": { textDecoration: "underline" }
  },

  timestampStickerLeft: {
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: theme.palette.background.paper,

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "2px",
  },

  timestampStickerRight: {
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: theme.palette.primary.messageBox,

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "3px",
  },

  timestampStarred: { display: "flex", alignItems: "center", gap: "5px", },
  timeStampStar: { fontSize: 12, },

  notificationSpan: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "75%",
    backgroundColor: theme.palette.background.paper,
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: theme.palette.background.paper,
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: { color: "#808888", padding: 8, alignSelf: "center", marginLeft: "0px", },
  ackIcons: { fontSize: 18, verticalAlign: "middle", marginLeft: 4, },
  deletedIcon: { fontSize: 18, verticalAlign: "middle", marginRight: 4, },
  ackDoneAllIcon: { color: theme.palette.secondary.ackCheck, fontSize: 18, verticalAlign: "middle", marginLeft: 4, },

  downloadMedia: {
    display: "flex",
    flexDirection: "column",
    transition: "all 0.3s",
    gap: "1em",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  lastMessageTicketContainer: {
    display: "flex",
    flexDiretion: "row",
    alignItems: "center",
    justifyContent: "space-between",
    userSelect: "none",
  },

  lastMessageTicketDivider: { width: "100%", height: "1px", },
  lastMessageTicketSpan: { width: "85%", textAlign: "center", },

  stickerMessage: {
    maxHeight: 225,
    maxWidth: 225,
    borderRadius: 5,
    transition: "all 0.3s",
    [theme.breakpoints.down("sm")]: { maxHeight: 150, maxWidth: 150, },
  },

  deletedStickerMessage: { opacity: 0.7, filter: "grayscale(80%)", },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  },

  //  *****************
  //  ** Dot Elastic **
  //  *****************
  dotElasticContainer: {
    backgroundColor: theme.palette.background.paper,
    width: "50px",
    
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 8,
    boxShadow: "0 1px 1px #b3b3b3",
    
    
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MessagesList = ({ ticketId, ticketType, ticketStatus,
  isGroup, isForwarding, updateIsForwarding, selectedForwardMessages,
  updateSelectedForwardMessages, setAudioPlayer, setCurrentPlayingAudioTime,
  setLastMessage, whatsappId, isContactBlocked, isContactInactive, botAnswerDelay,
  setTabOpen, messagesList, dispatch }) => {  
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { user: { profile: userProfile, id: userId } } = useContext(AuthContext);
  const currentTicketId = useRef(ticketId);
  const currentWhatsappId = useRef(whatsappId);

  const lastMessageRef = useRef();

  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);

  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [selectedPhoneNumberMessage, setSelectedPhoneNumberMessage] = useState(null);

  const [messageEditModalOpen, setMessageEditModalOpen] = useState(false);
  const [messageEditHistoryModalOpen, setMessageEditHistoryModalOpen] = useState(false);
  const [messagePropertiesModalOpen, setMessagePropertiesModalOpen] = useState(false);
  const [selectedMessageEditHistory, setSelectedMessageEditHistory] = useState(null);
  const [selectedMessageProperties, setSelectedMessageProperties] = useState(null);

  const [pollVotesModalOpen, setPollVotesModalOpen] = useState(false);
  const [selectedPollVotesMessage, setSelectedPollVotesMessage] = useState([]);

  const [openPrivateChatConversationModal, setOpenPrivateChatConversationModal] = useState(false);
  const [selectedContactPrivateChat, setSelectedContactPrivateChat] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [phoneNumberMessageOptionsMenuAnchorEl, setPhoneNumberMessageOptionsMenuAnchorEl] = useState(null);

  const messageOptionsMenuOpen = Boolean(anchorEl);
  const phoneNumberMessageOptionsMenuOpen = Boolean(phoneNumberMessageOptionsMenuAnchorEl);

  const { whatsApps } = useWhatsApps();
  const [selectedWhatsappId, setSelectedWhatsappId] = useState("");

  const [shouldDisplayScrollButton, setShouldDisplayScrollButton] = useState(false);
  const [areThereNewMessagesScroll, setAreThereNewMessagesScroll] = useState(false);

  // Reseting "lastMessage". It is needed when user changes ticket view from
  // Ticket A to Ticket B.
  setLastMessage(null);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (whatsApps.length === 1) { setSelectedWhatsappId(whatsApps[0].id); }
  }, [whatsApps]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
    currentTicketId.current = ticketId;
    if (whatsappId !== undefined && whatsappId !== null) currentWhatsappId.current = whatsappId;
  }, [dispatch, ticketId, whatsappId, ticketType]);

  useEffect(() => {
    if (
      (whatsappId !== undefined && whatsappId !== null && ticketType === 0)
     || ticketType === 1 || ticketType === 2 || ticketType === 3
    ) {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        const fetchMessages = async () => {
          try {
            const { data } = await api.get("/messages/" + ticketId, {
              params: { pageNumber, whatsappId },
            });

            if (
              (currentTicketId.current === ticketId && currentWhatsappId.current === whatsappId)
              || ticketType === 1 || ticketType === 2 || ticketType === 3
            ) {
              dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
              setHasMore(data.hasMore);
              setLoading(false);
            }

            if (pageNumber === 1 && data.messages.length > 1) {
              scrollToBottom(false);
            }
          } catch (error) {
            setLoading(false);
            console.log("Messages List Use Effect 2 Error:", error);
            toastError(error);
          }
        };
        fetchMessages();
      }, 500);
      return () => { clearTimeout(delayDebounceFn); };
    }
    
  }, [dispatch, pageNumber, ticketId, whatsappId, ticketType]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const checkForwardMessageCheckbox = async () => {
        document.getElementById(`checkbox-${selectedMessage.id}`).click();
      };

      if (isForwarding) { checkForwardMessageCheckbox(); }
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [selectedMessage, isForwarding]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });

    return () => {
      document.removeEventListener("fullscreenchange", setIsFullscreen(!!document.fullscreenElement));
    };
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const loadMore = () => { setPageNumber((prevPageNumber) => prevPageNumber + 1); };

  const handleShareMessage = (mediaUrl) => {
    if (!navigator.canShare) {
      toast.info(i18n.t("shareMessage.notSupport"));
    } else {
      fetch(mediaUrl)
        .then(res => res.blob())
        .then(async blob => {
          const file = new File(
            [blob],
            `fileToShare.${blob.type.split("/")[1]}`,
            { type: blob.type }
          );

          const shareData = {
            title: "📱 BestZap",
            text: "",
            url: "https://bestzap.fxsistemas.com.br/",
            files: [file],
          };

          if (navigator.canShare(shareData)) { await navigator.share(shareData); }
          else { toast.error(i18n.t("shareMessage.errorSharing")); }
      });
    }
  };

  const scrollToBottom = (smoothBehavior) => {
    if (smoothBehavior && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
    else if (!smoothBehavior && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScrollToBottomButton = (smoothBehavior) => {
    setAreThereNewMessagesScroll(false);
    scrollToBottom(smoothBehavior);
  };

  const handleScroll = (e) => {
    const { scrollTop, offsetHeight, scrollHeight } = e.currentTarget;

    if (scrollTop + offsetHeight>= scrollHeight ){
      setShouldDisplayScrollButton(false);
      setAreThereNewMessagesScroll(false);
    }
    else { setShouldDisplayScrollButton(true); }

    if (!hasMore) return;
    if (scrollTop === 0) { document.getElementById("messagesList").scrollTop = 1; }
    if (loading) { return; }
    if (scrollTop < 800) { loadMore(); }
  };

  const handleOpenPrivateChatConversationModal = (selectedContact) => {
    setSelectedContactPrivateChat(selectedContact);
    setOpenPrivateChatConversationModal(true);
  };

  const handleClosePrivateChatConversationModal = () => {
    setSelectedContactPrivateChat(null);
    setOpenPrivateChatConversationModal(false);
    setSelectedWhatsappId("");
  };

  const handleOpenPrivateChat = async (selectedContactId) => {
    if (!selectedContactId) return;
    setLoading(true);
    if (!selectedWhatsappId || selectedWhatsappId === undefined || selectedWhatsappId === "") {
      toast.error(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
    }
    else {
      try {
        const { data: ticket } = await api.post("/tickets", {
          contactId: selectedContactId,
          userId: userId,
          status: "open",
          isCreatedByUser: true,
          whatsappId: selectedWhatsappId
        });
        history.push(`/tickets/${ticket.id}`);
      } catch (error) {
        console.log("Handle Open Private Chat Error:", error);
        toastError(error);
      }
    }

    setLoading(false);
  };

  const selectForwardMessage = (messageId) => {
    const checkbox = document.getElementById(`checkbox-${messageId}`);

    // checkbox being selected
    if (checkbox.checked) {
      if (selectedForwardMessages.length < 20) {
        updateSelectedForwardMessages([...selectedForwardMessages, messageId]);
      }
      else {
        checkbox.checked = false;
        toast.error(i18n.t("messagesList.toast.forwardMessage.limit"));
      }
    }

    // checkbox being unselected
    else {
      updateSelectedForwardMessages(selectedForwardMessages.filter(message => message !== messageId));
    }
  }

  const handleOpenMessageOptionsMenu = (e, message) => {
    if (ticketStatus === undefined || ticketStatus === null) { return; }
    if (ticketType === undefined || ticketType === null) { return; }
    if (ticketStatus === "pending" && userProfile === "user" && message.mediaType !== "chat") { return; }
    if (ticketStatus === "closed" || ticketStatus === "channel") { return; }
    if (message.isDeleted) { return; }
    if (message.contact && message.contact.name === "WhatsApp Official ✔️") { return; }
    if (isContactBlocked) { return; }
    if (isContactInactive) { return; }
    
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenPhoneNumberMessageOptionsMenu = (event, phoneNumber, isMsgFromMe) => {
    const targetClassName = isMsgFromMe ? "messageRight" : "messageLeft";
    const parentNodeElement = getParentNodeByClassName(targetClassName, event.currentTarget);

    setPhoneNumberMessageOptionsMenuAnchorEl(parentNodeElement);
    setSelectedPhoneNumberMessage(phoneNumber);
  };

  const handleClosePhoneNumberMessageOptionsMenu = () => {
    setPhoneNumberMessageOptionsMenuAnchorEl(null);
  };

  const handleReaction = async (event, reaction, messageId, ticketId) => {
    document.getElementById(`reactionOptions-${messageId}`).style.display = "none";

    emojisplosion({
      physics: { gravity: 0.5, },
      emojis: [reaction],
      position: () => ({ x: event.clientX, y: event.clientY }),
    });
    
    await api.put(`/reaction/${messageId}`, { reaction, ticketId });
  };

  const handleOpenPollVotesModal = (message) => {
    setSelectedPollVotesMessage(message);
    setPollVotesModalOpen(true);
  };

  const handleClosePollVotesModal = () => { 
    setPollVotesModalOpen(false);
    setSelectedPollVotesMessage([]);
  };

  const handleOpenMessageEditModal = () => {
    setMessageEditModalOpen(true);
  };

  const handleCloseMessageEditModal = () => {
    setMessageEditModalOpen(false);
  };

  const handleOpenMessageEditHistoryModal = (message) => {
    setMessageEditHistoryModalOpen(true);
    setSelectedMessageEditHistory(message);
  };

  const handleCloseMessageEditHistoryModal = () => {
    setMessageEditHistoryModalOpen(false);
    setSelectedMessageEditHistory(null);
  };

  const handleOpenMessagePropertiesModal = (message) => {
    setMessagePropertiesModalOpen(true);
    setSelectedMessageProperties(message);
  };

  const handleCloseMessagePropertiesModal = () => {
    setMessagePropertiesModalOpen(false);
    setSelectedMessageProperties(null);
  };

  const checkMessageMedia = (message, isQuotedMessage=false) => {   
    if (message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    
    else if (message.mediaType === "button") { 
      const buttonMessage = message.body;
      const buttonMessageTitleFooter = buttonMessage.split("##");
      const buttonMessageArray = buttonMessageTitleFooter[0].split("||");
      const buttonTitle = buttonMessageTitleFooter[1];
      const buttonFooter = buttonMessageTitleFooter[2];

      const buttonBody = buttonMessageArray[0];
      const buttonOptionsArray = buttonMessageArray.slice(1);

      return(
        <>
          <b>{buttonTitle}</b>
          <p>{buttonBody}</p>
          <i className={classes.buttonListFooter}>{buttonFooter}</i>
          <hr />
          <center>
            {buttonOptionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                disabled
              >
                {option}
              </Button>
            ))}
          </center>
        </>
      );

    }
    
    else if (message.mediaType === "list") { 
      const listMessage = message.body;
      const listMessageArray = listMessage.split("||");

      const headerArray = listMessageArray[0].split("%%");
      const optionsArray = listMessageArray.slice(1);
      return (
        <>
          <b>{headerArray[0]}</b>
          <p>{headerArray[1]}</p>
          <i className={classes.buttonListFooter}>{headerArray[3]}</i>
          <center>
            <Button
              startIcon={<FormatListBulletedOutlined />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
              disabled
            >
              {headerArray[2]}
            </Button>
          </center>
          
          <hr />

          <center>
            {optionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                href={message.mediaUrl}
                disabled
              >
                {option.split("%%")[0]}
              </Button>
            ))}
          </center>
        </>
      );
    }
    
    else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("waid=") !== -1) {
            obj.push({ number: values[ind].split("waid=")[1] });
          }

          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0]?.number} enableHandleNewChat={true} />
    }
    
    else if (message.mediaType === "multi_vcard") {
      const vcardsLimiter = "\n----vcardLimiter----\n";
      const vcards = message.body.split(vcardsLimiter).filter(vcard => vcard !== "");
      const vcardsElements = [];
      const contactsList = [];

      for (let vcardIndex = 0; vcardIndex < vcards.length; vcardIndex += 1) {
        vcardsElements.push(vcards[vcardIndex].split("\n"));
      }

      for (let vcardElementIndex = 0; vcardElementIndex < vcardsElements.length; vcardElementIndex += 1) {
        try {
          contactsList.push({
            number: vcardsElements[vcardElementIndex][4].split("waid=")[1].split(":")[0],
            name: vcardsElements[vcardElementIndex][3].split(":")[1],
          })
        } catch (err) {
          try {
            contactsList.push({
              number: vcardsElements[vcardElementIndex][6].split("waid=")[1].split(":")[0],
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            })
          } catch (err) {
            contactsList.push({
              number: undefined,
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            });
          }
        }
      }

      return <MultiVcardPreview contacts={contactsList} enableStartChat={true} />;
    }
    
    else if (message.mediaType === "image" && isQuotedMessage && !message.isViewOnce) {
      return (
        <ModalImageCors
          imageUrl={message.mediaUrl}
          replyMessage={true}
          isDeleted={message.isDeleted}
          ticketId={ticketId}
        />
      );
    }
    
    else if (message.mediaType === "image" && !isQuotedMessage && !message.isViewOnce) {
      return (
        <ModalImageCors
          imageUrl={message.mediaUrl}
          replyMessage={false}
          isDeleted={message.isDeleted}
          ticketId={ticketId}
        />
      );
    }
    
    else if (message.mediaType === "image" && isQuotedMessage && message.isViewOnce) {
      return <ViewOnceRepliedMessage isPhoto={true} />
    }
    
    else if (message.mediaType === "image" && !isQuotedMessage && message.isViewOnce) {
      return <ViewOnceMessage message={message} isPhoto={true} />
    }
    
    else if (message.mediaType === "sticker") {
      return (
        <img
          className={clsx(classes.stickerMessage, {[classes.deletedStickerMessage]: message.isDeleted,})}
          src={message.mediaUrl} alt={message.body} width={225} height={225}
        />
      );
    }
    
    else if (message.mediaType === "audio") {
      return (
        <Audio
          transcript={message.transcript}
          url={message.mediaUrl}
          setAudioPlayer={setAudioPlayer}
          setCurrentPlayingAudioTime={setCurrentPlayingAudioTime}
          ticket={message.ticket}
          messageId={message.id}
          currentTicketId={ticketId}
          isDeleted={message.isDeleted}
          isVoice={message.isVoice}
          isFromMe={message.fromMe}
        />
      );
    }
    
    else if (message.mediaType === "video" && !message.isViewOnce) {
      let mediaClass = isFullscreen
        ? `${classes.messageMediaFullScreen}`
        : `${classes.messageMedia}`;

      if (message.isDeleted) mediaClass += ` ${classes.deletedMediaMessage}`;

      return (
        <video
          className={mediaClass}
          src={message.mediaUrl}
          controls={!message.isGif}
          autoPlay={message.isGif}
          muted={message.isGif}
          loop={message.isGif}
        />
      );
      
    }
    
    else if (message.mediaType === "video" && isQuotedMessage && message.isViewOnce) {
      return <ViewOnceRepliedMessage isPhoto={false} />
    }
    
    else if (message.mediaType === "video" && !isQuotedMessage && message.isViewOnce) {
      return <ViewOnceMessage message={message} isPhoto={false} />
    }
    
    else if (message.mediaType === "ptv") {
      return (
        <Videoplayer size={200} src={message.mediaUrl} boundaryColor={"#ff6961"} />
      );
    } else if (message.mediaType === "poll_creation" && !isQuotedMessage && !message.isRating) {
      return <PollPreview message={message} handleOpenPollVotesModal={handleOpenPollVotesModal} lastMessageRef={lastMessageRef} readonly={true} />
    } else if (message.mediaType === "poll_creation" && !isQuotedMessage && message.isRating) {
      return <PollPreview message={message} handleOpenPollVotesModal={handleOpenPollVotesModal} lastMessageRef={lastMessageRef} readonly={true} />
    }
    
    else if (message.mediaType === "poll_creation" && isQuotedMessage) {
      return <PollPreviewQuotedMessage message={message} />
    }

    else if (message.mediaType === "event" && !isQuotedMessage) {
      return <EventPreview message={message} />;
    }

    else if (message.mediaType === "event" && isQuotedMessage) {
      return <EventPreviewQuotedMessage message={message} />;
    }
    
    else if (message.mediaType === "group_invite") {
      return <GroupInvitePreview message={message} isQuotedMessage={isQuotedMessage} />
    }
    
    else {
      const mediaExtension = message.mediaUrl.split(".")[message.mediaUrl.split(".").length - 1];
      const handleClick = (event) => {
        event.preventDefault();
        saveFile(message);
      }

      return (
        <>
          <div className={message.isDeleted ? `${classes.downloadMedia} ${classes.deletedMediaMessage}` : classes.downloadMedia}>
            {!isSmallScreen && !isQuotedMessage && mediaExtension === "pdf" && (
              <embed src={message.mediaUrl} width="500" height="375" type="application/pdf" />
            )}

            {!isSmallScreen && !isQuotedMessage && mediaExtension === "txt" && (
              <embed src={message.mediaUrl} width="500" height="375" type="text/plain"/>
            )}

            {!isSmallScreen && !isQuotedMessage && mediaExtension === "md" && (
              <embed src={message.mediaUrl} width="500" height="375" type="text/markdown"/>
            )}

            <Button
              startIcon={<GetApp />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href="#"
              onClick={handleClick}
              className={classes.floatingButton}
            >{i18n.t("messagesInput.download")}</Button>

            {isQuotedMessage && message.body}
          </div>
          <Divider />
        </>
      );
    }
  };

  const checkInteractiveMessage = (message) => {
    if (message.mediaType === "list_creation") {
      return (
        <ListPreview message={message} ticketId={ticketId} />
      );
    } else if (message.mediaType === "button_creation") {
      return (
        <ButtonPreview message={message} ticketId={ticketId} />
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1 || message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderEndTicket = (message) => {
    if (message.isLastMessage && message.ticket.type === 0) {
      return (
        <>
          <br />
          <div className={classes.lastMessageTicketContainer}>
            {message.ticket.user?.name && (
              <>
              <hr className={classes.lastMessageTicketDivider} />
              <span className={classes.lastMessageTicketSpan}>
                {`${i18n.t("messagesListLabels.ticketEnd")} #${message.ticketId} (${message.ticket.user?.name}) (${message.ticket.whatsapp?.name}) ${message.ticket.isClosedAt ? `(${extractDayMonthYearFromStringDateTime(message.ticket.isClosedAt)})` : ""}`}
              </span>
              <hr className={classes.lastMessageTicketDivider} />
              </>
            )}

            {!message.ticket.user?.name && message.ticket.directClosedByUser?.name && (
              <>
              <hr className={classes.lastMessageTicketDivider} />
              <span className={classes.lastMessageTicketSpan}>
                {`${i18n.t("messagesListLabels.ticketEnd")} #${message.ticketId} (${message.ticket.directClosedByUser?.name}) (${message.ticket.whatsapp?.name})  ${message.ticket.isClosedAt ? `(${extractDayMonthYearFromStringDateTime(message.ticket.isClosedAt)})` : ""}`}
              </span>
              <hr className={classes.lastMessageTicketDivider} />
              </>
            )}

            {!message.ticket.user?.name && !message.ticket.directClosedByUser?.name && (
              <>
              <hr className={classes.lastMessageTicketDivider} />
              <span className={classes.lastMessageTicketSpan}>
                {`${i18n.t("messagesListLabels.ticketEnd")} #${message.ticketId} (-) (${message.ticket.whatsapp?.name}) ${message.ticket.isClosedAt ? `(${extractDayMonthYearFromStringDateTime(message.ticket.isClosedAt)})` : ""}`}
              </span>
              <hr className={classes.lastMessageTicketDivider} />
              </>
            )}
          </div>
          <br />
        </>
      );
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    // Quoted Poll Messages are not shown when user is not admin and
    // poll is rating
    if (userProfile === "user" && message.quotedMsg?.isRating) return <></>
    return (
      <a className={classes.quotedMessageAnchor} href={`#messageContainer-${message.quotedMsg.id}`}>
        <div
          className={
            ticketType === 0
              ? clsx(classes.quotedContainerLeft, {
                [classes.quotedContainerRight]: message.fromMe
              })
              : clsx(classes.quotedContainerLeft, {
                [classes.quotedContainerRight]: ticketType === 3 && message.userId === userId
              })
          }
        >
          <span
            className={
              ticketType === 0
                ? clsx(classes.quotedSideColorLeft, {
                  [classes.quotedSideColorRight]: message.quotedMsg?.fromMe
                })
                : clsx(classes.quotedSideColorLeft, {
                  [classes.quotedSideColorRight]: ticketType === 3 && message.quotedMsg?.userId === userId
                })
              }
          ></span>

          <div className={classes.quotedMsg}>
            {!message.quotedMsg?.fromMe && (
              <span className={classes.messageContactName}>
                {message.quotedMsg?.contact?.name}
              </span>
            )}

            {message.quotedMsg?.mediaType !== "list" 
            && message.quotedMsg?.mediaType !== "button" 
            && message.quotedMsg?.mediaType !== "vcard" 
            && message.quotedMsg?.mediaType !== "multi_vcard"
            && message.quotedMsg?.mediaType !== "image" 
            && message.quotedMsg?.mediaType !== "sticker"
            && message.quotedMsg?.mediaType !== "audio" 
            && message.quotedMsg?.mediaType !== "video" 
            && message.quotedMsg?.mediaType !== "location" 
            && message.quotedMsg?.mediaType !== "application" 
            && message.quotedMsg?.mediaType !== "poll_creation"
            && message.quotedMsg?.mediaType !== "event"
            && message.quotedMsg?.mediaType !== "ptv"
            && message.quotedMsg?.mediaType !== "group_invite"
            && (
              <MessageBodyWrapper
                messageBody={message.quotedMsg?.body}
                isMessageFromMe={message.fromMe}
                handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
              />
            )}

            {message.quotedMsg?.mediaType === "button" && (
              <>
                <b>{message.quotedMsg?.body.split("##")[1]}</b>
                <p>{message.quotedMsg?.body.split("||")[0]}</p>
                <i className={classes.buttonListFooter}>{message.quotedMsg?.body.split("##")[2]}</i>
              </>
            )}

            {message.quotedMsg?.mediaType === "list" && (
              <>
                <b>{message.quotedMsg?.body.split("||")[0].split("%%")[0]}</b>
                <p>{message.quotedMsg?.body.split("||")[0].split("%%")[1]}</p>
                <i className={classes.buttonListFooter}>{message.quotedMsg?.body.split("||")[0].split("%%")[3]}</i>
              </>
            )}

            {(message.quotedMsg?.mediaType === "vcard" 
            || message.quotedMsg?.mediaType === "multi_vcard"
            || message.quotedMsg?.mediaType === "image"
            || message.quotedMsg?.mediaType === "sticker"
            || message.quotedMsg?.mediaType === "audio"
            || message.quotedMsg?.mediaType === "video"
            || message.quotedMsg?.mediaType === "location"
            || message.quotedMsg?.mediaType === "poll_creation"
            || message.quotedMsg?.mediaType === "event"
            || message.quotedMsg?.mediaType === "ptv"
            || message.quotedMsg?.mediaType === "application"
            || message.quotedMsg?.mediaType === "group_invite") && checkMessageMedia(message.quotedMsg, true)}
          </div>
        </div>
      </a>
    );
  };

  const handleContextMenu = (event, message) => {
    event.preventDefault();
    if (message.isBroadcast) { return; }
    handleOpenMessageOptionsMenu(event, message);
  };

  const renderGroupNotification = (message) => {
    return (
      <span className={classes.notificationSpan}key={message.id}>
        <div className={classes.dailyTimestampText}>{message.body}</div>
      </span>
    );
  };

  const renderReactionOptions = (messageId, ticketId) => {
    return (
      <div id={`reactionOptions-${messageId}`} className={classes.reactionOptions}>
        <span className={`${classes.reactionOption} ${classes.reactionOptionOpacity}`} onClick={(event) => handleReaction(event, "", messageId, ticketId)}><NotInterestedOutlined /></span>
        <span className={classes.reactionOption} onClick={(event) => handleReaction(event, "👍", messageId, ticketId)}>👍</span>
        <span className={classes.reactionOption} onClick={(event) => handleReaction(event, "❤️", messageId, ticketId)}>❤️</span>
        <span className={classes.reactionOption} onClick={(event) => handleReaction(event, "😂", messageId, ticketId)}>😂</span>
        <span className={classes.reactionOption} onClick={(event) => handleReaction(event, "😯", messageId, ticketId)}>😯</span>
        <span className={classes.reactionOption} onClick={(event) => handleReaction(event, "😥", messageId, ticketId)}>😥</span>
        <span className={classes.reactionOption} onClick={(event) => handleReaction(event, "😡", messageId, ticketId)}>😡</span>
        <span className={classes.reactionOption} onClick={(event) => handleReaction(event, "🙏", messageId, ticketId) }>🙏</span>
        <span className={classes.reactionOption} onClick={(event) => handleReaction(event, "🎉", messageId, ticketId) }>🎉</span>
      </div>
    );
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const lastMessageList = messagesList.filter(message => {
          return message.fromMe
            && [
              "application",
              "chat",
              "image",
              "internal",
              "transference_observation",
              "video"
            ].includes(message.mediaType);
        }
      );

      setLastMessage(lastMessageList[lastMessageList.length - 1]);

      const viewMessagesList = messagesList.map((message, index) => {
        if (message.ack !== 9) {


          //  **************************
          //  ** Notification Message **
          //  **************************
          if (message.mediaType === "notification") {
            return (renderGroupNotification(message));
          }

          //  **********************
          //  ** Internal Message **
          //  **********************
          else if (["internal", "transference_observation"].includes(message.mediaType)) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageRight}`}>
                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div
                    className={`${classes.messageRight} ${classes.internalMessage}`}
                    onContextMenu={(e) => handleContextMenu(e, message)}
                  >
                    {message.mediaType !== "list" && message.mediaType !== "list_response" 
                    && message.mediaType !== "button" && message.mediaType !== "buttons_response" 
                    && message.mediaType !== "template_button_reply"
                    && !message.isBroadcast
                    && !isContactBlocked
                    && !isContactInactive
                    && ticketStatus !== "channel"
                    && (                    
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      ><ExpandMore /></IconButton>
                    )}


                    <div
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted })}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >
                     
                      {message.mediaType !== "list" 
                      && message.mediaType !== "poll_creation"
                      && message.mediaType !== "event"
                      && message.mediaType !== "group_invite"
                      && message.mediaType !== "multi_vcard"
                      && message.mediaType !== "vcard"
                      && message.body !== ""
                      && (
                        <MessageBodyWrapper
                          messageBody={message.body}
                          isMessageFromMe={message.fromMe}
                          handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                        />
                      )}
  
                      <span className={classes.timestamp}>
                        {message.isEdited && (
                          <span 
                            className={classes.editedMessage}
                            onClick={() => handleOpenMessageEditHistoryModal(message)}
                          >
                            {i18n.t("messagesInput.editedMessage") + " "}
                          </span>
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
                  </div>

                  {/* 
                    **********************
                    ** User Profile Pic **
                    **********************
                  */}
                  {message.user && message.user !== undefined
                   && message.user.name && message.user.name !== undefined
                   && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.user.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.user.name}
                          src={message.user.name}
                          className={classes.contactAvatar}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                {renderEndTicket(message)}
              </React.Fragment>
            );
          }

          //  *************************
          //  ** Rating Poll Message **
          //  *************************
          //
          // - Message is not shown when user is not admin and message
          // is a rating poll
          //
          else if (userProfile === "user" && message.isRating) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                {renderEndTicket(message)}
              </React.Fragment>
            );
          }
          

          //  *******************************************
          //  ** Message Sent By Contact - Non-Sticker **
          //  *******************************************
          else if (
            (!message.fromMe || (ticketType === 3 && message.userIdInternalChat === userId))
            && message.mediaType !== "sticker"
          ) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                
                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageLeft}`}>
  
                  {/* 
                    ******************************
                    ** Forward Message Checkbox **
                    ******************************
                  */}
                  {isForwarding 
                  && !message.isDeleted
                  && message.mediaType !== "call" 
                  && message.mediaType !== "multi_vcard"
                  && message.mediaType !== "poll_creation"
                  && message.mediaType !== "event"
                  && message.mediaType !== "group_invite"
                  && message.mediaType !== "list_creation"
                  && message.mediaType !== "button_creation"
                  && message.mediaType !== "list_response"
                  && message.mediaType !== "button_response"
                  && !message.isViewOnce
                  && (
                    <div className={classes.forwardMessageCheckbox}>
                      <Checkbox 
                        id={`checkbox-${message.id}`}
                        onClick={() => selectForwardMessage(message.id) }
                      />
                    </div>
                  )}
                  


                  {/* 
                    ******************************
                    ** Group Member Profile Pic **                  
                    ******************************
                  */}
                  {message.ticket.status === "group" && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.contact?.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.contact?.name}
                          src={message.contact?.profilePicUrl}
                          className={classes.contactAvatar}
                          onClick={() => handleOpenPrivateChatConversationModal(message.contact)}
                        />
                      </Tooltip>
                    </div>
                  )}
  
  
  
                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={classes.messageLeft} onContextMenu={(e) => handleContextMenu(e, message)}>
                    {!isContactBlocked && !isContactInactive && ticketStatus !== "channel" && (
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      ><ExpandMore /></IconButton>
                    )}
                    
                    {isGroup && (
                      <span className={classes.messageContactName}>
                        {message.contact?.name}
                      </span>
                    )}

                    {message.quotedMsg && (
                      <div 
                        className={classes.textContentItem}
                        style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                      >
                        {renderQuotedMessage(message)}
                      </div>
                    )}

                    {(message.mediaUrl 
                    || message.mediaType === "location" 
                    || message.mediaType === "vcard"
                    || message.mediaType === "multi_vcard"
                    || message.mediaType === "poll_creation"
                    || message.mediaType === "event"
                    || message.mediaType === "group_invite"
                    ) && checkMessageMedia(message)}
                    
                    
                    {/* 
                      ***************
                      ** TimeStamp **
                      ***************
                    */}
                    <div 
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted })}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >
                      {message.isForwarded && (
                        <>
                          <i className={classes.forwardedMessage}>
                            <ReplyOutlined className={classes.horizontalFlip} />{i18n.t("messagesInput.forwardedMessage")}
                          </i>
                          <br />
                        </>
                      )}

                      {message.mediaType !== "call"
                      && message.mediaType !== "poll_creation" 
                      && message.mediaType !== "event"
                      && message.mediaType !== "group_invite"
                      && message.mediaType !== "multi_vcard"
                      && message.mediaType !== "vcard"
                      && message.mediaType !== "location"
                      && message.body !== ""
                      && (
                        <MessageBodyWrapper
                          messageBody={message.body}
                          isMessageFromMe={message.fromMe}
                          handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                        />
                      )}

                      {(message.mediaType === "audio"
                       || message.mediaType === "image"
                       || message.mediaType === "video"
                       || !message.isGif)
                       && (<br />)}

                      {(message.adsSourceUrl || message.adsTitle || message.adsThumbnailUrl)
                        && <AdsMessage message={message} redirectFlag={true} />}

                      {message.mediaType === "call" && message.isVideoCall && (
                        <div className={classes.callWrapper}>
                          <MissedVideoCallRounded className={classes.missedCall} /> 
                          <MessageBodyWrapper
                            messageBody={message.body}
                            isMessageFromMe={message.fromMe}
                            handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                          />
                        </div>
                      )}

                      {message.mediaType === "call" && !message.isVideoCall && (
                        <div className={classes.callWrapper}>
                          <PhoneMissedRounded className={classes.missedCall} />
                          <MessageBodyWrapper
                            messageBody={message.body}
                            isMessageFromMe={message.fromMe}
                            handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                          />
                        </div>
                      )}

                      <span className={classes.timestamp}>
                        {message.isEdited && (
                          <span 
                            className={classes.editedMessage}
                            onClick={() => handleOpenMessageEditHistoryModal(message)}
                          >
                              {i18n.t("messagesInput.editedMessage") + " "}
                            </span>
                        )}

                        {message.isDeleted && (
                          <Block fontSize="small" className={classes.deletedIcon} />
                        )}

                        {message.isStarred && (
                          <StarOutlined className={classes.timeStampStar} />
                        )}

                        {message.isBroadcast && (
                          <RecordVoiceOverRounded className={classes.timeStampStar} />
                        )}

                        {message.isStatusReply && (
                          <FilterTiltShiftOutlined className={classes.timeStampStar} />
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                      </span>
                    </div>
                    

                    {/* 
                      ***************
                      ** Reactions **
                      ***************
                    */}
                    {(message.contactReaction || message.fromMeReaction) && (
                      <div className={classes.reactionList}>
                        {message.contactReaction && (
                          <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>
                              {message.contactReaction}
                            </span>
                          </Tooltip>
                        )}
  
                        {message.fromMeReaction && (
                          <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>
                              {message.fromMeReaction}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    )}

                    {/*
                      ************************
                      ** Reaction Container **
                      ************************
                    */}
                    {renderReactionOptions(message.id, ticketId)}
                  </div>
                  
                  {(
                    (message?.contactId !== null && !isContactBlocked && !isContactInactive)
                    || ticketType === 3
                  )
                    && !message.isDeleted
                    && (
                      <div className={classes.infoMessageContainer}>
                        {/* 
                          *********************
                          ** Reaction Button **
                          *********************
                        */}
                        <span id="reactionButton" className={classes.reactionButton} onClick={() => {
                          const reactionOptions = document.getElementById(`reactionOptions-${message.id}`);
                          if (reactionOptions.style.display === "flex") { reactionOptions.style.display = "none"; }
                          else { reactionOptions.style.display = "flex"; }
                        }}>
                          <SentimentSatisfiedAltOutlined />
                        </span>



                        {/* 
                          ******************
                          ** Share Button **
                          ******************
                        */}
                        {(message.mediaType === "image" 
                          || (message.mediaType === "video" && !message.isGif))
                          && !message.isViewOnce
                          && (
                          <span
                            id="shareButton"
                            className={classes.reactionButton} 
                            onClick={() => handleShareMessage(message.mediaUrl)}
                          >
                            <ShareOutlined />
                          </span>
                        )}
                      </div>
                  )}
                </div>

                {/* 
                  **************************
                  ** Interactive Messages **
                  **************************
                */}
                {(message.mediaType !== "list_creation" || message.mediaType !== "button_creation") && (
                  checkInteractiveMessage(message)
                )}
                {renderEndTicket(message)}
              </React.Fragment>
            );
          } 
          
          

          //  ***************************************
          //  ** Message Sent By Contact - Sticker **
          //  ***************************************
          else if (
            (!message.fromMe || (ticketType === 3 && message.userIdInternalChat === userId))
            && message.mediaType === "sticker"
          ) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageLeft}`}>


                  {/* 
                    ******************************
                    ** Forward Message Checkbox **
                    ******************************
                  */}
                  {isForwarding
                  && !message.isDeleted
                  && message.mediaType !== "call"
                  && message.mediaType !== "multi_vcard"
                  && message.mediaType !== "poll_creation"
                  && message.mediaType !== "event"
                  && message.mediaType !== "group_invite"
                  && !message.isViewOnce
                  && (
                    <div className={classes.forwardMessageCheckbox}>
                      <Checkbox 
                        onClick={() => selectForwardMessage(message.id) }
                        id={`checkbox-${message.id}`}
                      />
                    </div>
                  )}



                  {/* 
                    ******************************
                    ** Group Member Profile Pic **                  
                    ******************************
                  */}
                  {message.ticket.status === "group" && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.contact?.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.contact?.name}
                          src={message.contact?.profilePicUrl}
                          className={classes.contactAvatar}
                          onClick={() => handleOpenPrivateChatConversationModal(message.contact)}
                        />
                      </Tooltip>
                    </div>
                  )}



                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={classes.messageLeftSticker} onContextMenu={(e) => handleContextMenu(e, message)}>
                    {!isContactBlocked && !isContactInactive && ticketStatus !== "channel" && (
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      ><ExpandMore /></IconButton>
                    )}

                    {message.isForwarded && (
                      <>
                        <i className={classes.forwardedMessage}>
                          <ReplyOutlined className={classes.horizontalFlip} />{i18n.t("messagesInput.forwardedMessage")}
                        </i>
                        <br />
                      </>
                    )}

                    {message.quotedMsg && (
                      <div
                        className={classes.textContentItem}
                        style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                      >
                        {renderQuotedMessage(message)}
                      </div>
                    )}

                    <img 
                      className={clsx(classes.stickerMessage, {[classes.deletedStickerMessage]: message.isDeleted,})}
                      src={message.mediaUrl} alt={message.body} width={225} height={225}
                    />
                    
                    <div 
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted,})}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >
                      <span className={`${classes.timestamp} ${classes.timestampStickerLeft}`}>
                        {message.isDeleted && (
                          <Block fontSize="small" className={classes.deletedIcon} />
                        )}

                        {message.isStarred && (
                          <StarOutlined className={classes.timeStampStar} />
                        )}

                        {message.isBroadcast && (
                          <RecordVoiceOverRounded className={classes.timeStampStar} />
                        )}

                        {message.isStatusReply && (
                          <FilterTiltShiftOutlined className={classes.timeStampStar} />
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                      </span>
                    </div>
                    
                    {(message.contactReaction || message.fromMeReaction) && (
                      <div className={classes.reactionListSticker}>
                        {message.contactReaction && (
                          <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>{message.contactReaction}</span>
                          </Tooltip>
                        )}
  
                        {message.fromMeReaction && (
                          <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>{message.fromMeReaction}</span>
                          </Tooltip>
                        )}
                      </div>
                    )}

                    {/*
                      ************************
                      ** Reaction Container **
                      ************************
                    */}
                    {renderReactionOptions(message.id, ticketId)}
                  </div>



                  {/* 
                    *********************
                    ** Reaction Button **
                    *********************
                  */}
                  {
                   (
                    (message?.contactId !== null && !isContactBlocked && !isContactInactive)
                    || ticketType === 3
                   )
                   && !message.isDeleted
                   && (
                    <span id="reactionButton" className={classes.reactionButton} onClick={() => {
                      const reactionOptions = document.getElementById(`reactionOptions-${message.id}`);
                      if (reactionOptions.style.display === "flex") { reactionOptions.style.display = "none"; }
                      else { reactionOptions.style.display = "flex"; }
                    }}>
                      <SentimentSatisfiedAltOutlined />
                    </span>                
                  )}
                </div>
                {renderEndTicket(message)}
              </React.Fragment>
            );
          }

          
          
          //  **************************************
          //  ** Message Sent By Me - Non-Sticker **
          //  **************************************
          else if (
            (message.fromMe || (ticketType === 3 && message.userInternalChat !== userId))
            && message.mediaType !== "sticker"
          ) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}

                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageRight}`}>

                  {/* 
                  ************************
                  ** Info MSG Container **
                  ************************
                  */}
                  {((message?.contactId !== null && !isContactBlocked && !isContactInactive)
                    || (message.ticket.type === 3))
                   && !message.isDeleted
                   && (
                    <div className={classes.infoMessageContainer}>
                      {/* 
                        ******************
                        ** Share Button **
                        ******************
                      */}
                      {(message.mediaType === "image" 
                        || (message.mediaType === "video" && !message.isGif))
                        && !message.isViewOnce
                        && (
                        <span
                          id="shareButton"
                          className={classes.reactionButton} 
                          onClick={() => handleShareMessage(message.mediaUrl)}
                        >
                          <ShareOutlined />
                        </span>
                      )}            
                    </div>
                  )}




                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={classes.messageRight} onContextMenu={(e) => handleContextMenu(e, message)}>
                    {message.mediaType !== "list" && message.mediaType !== "list_response" 
                    && message.mediaType !== "button" && message.mediaType !== "buttons_response" 
                    && message.mediaType !== "template_button_reply"
                    && !message.isBroadcast
                    && !isContactBlocked
                    && !isContactInactive
                    && ticketStatus !== "channel"
                    && (                    
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      ><ExpandMore /></IconButton>
                    )}

                    
                    {message.quotedMsg && (
                      <div
                        className={classes.textContentItem}
                        style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                      >
                        {renderQuotedMessage(message)}
                      </div>
                    )}


  
                    {(message.mediaUrl 
                    || message.mediaType === "location" 
                    || message.mediaType === "vcard" 
                    || message.mediaType === "multi_vcard"
                    || message.mediaType === "list" 
                    || message.mediaType === "button"
                    || message.mediaType === "poll_creation"
                    || message.mediaType === "event"
                    || message.mediaType === "group_invite"
                    ) && checkMessageMedia(message)}

                    <div
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted })}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >
                      {message.isForwarded && (
                        <>
                          <i className={classes.forwardedMessage}>
                            <ReplyOutlined className={classes.horizontalFlip} />{i18n.t("messagesInput.forwardedMessage")}
                          </i>
                          <br />
                        </>
                      )}
  
                      {message.isDeleted && (
                        <Block
                          color="disabled"
                          fontSize="small"
                          className={classes.deletedIcon}
                        />
                      )}

                      {(message.adsSourceUrl || message.adsTitle || message.adsThumbnailUrl)
                      && <AdsMessage message={message} redirectFlag={true} />}
  
                      {message.mediaType !== "list" 
                      && message.mediaType !== "poll_creation"
                      && message.mediaType !== "event"
                      && message.mediaType !== "group_invite"
                      && message.mediaType !== "multi_vcard"
                      && message.mediaType !== "vcard"
                      && message.mediaType !== "location"
                      && message.body !== ""
                      && (
                        <MessageBodyWrapper
                          messageBody={message.body}
                          isMessageFromMe={message.fromMe}
                          handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                        />
                      )}

                      {(message.mediaType === "audio"
                       || message.mediaType === "image"
                       || message.mediaType === "video"
                       || !message.isGif)
                       && (<br />)}
  
                      <span className={classes.timestamp}>
                        {message.isEdited && (
                          <span 
                            className={classes.editedMessage}
                            onClick={() => handleOpenMessageEditHistoryModal(message)}
                          >
                            {i18n.t("messagesInput.editedMessage") + " "}
                          </span>
                        )}

                        {message.isStarred && (
                          <StarOutlined className={classes.timeStampStar} />
                        )}

                        {message.isBroadcast && (
                          <RecordVoiceOverRounded className={classes.timeStampStar} />
                        )}

                        {message.isStatusReply && (
                          <FilterTiltShiftOutlined className={classes.timeStampStar} />
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
  
                    {(message.contactReaction || message.fromMeReaction) && (
                      <div className={classes.reactionList}>
                        {message.contactReaction && (
                          <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>
                              {message.contactReaction}
                            </span>
                          </Tooltip>
                        )}
  
                        {message.fromMeReaction && (
                          <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>
                              {message.fromMeReaction}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                  
                  

                  {/* 
                    **********************
                    ** User Profile Pic **
                    **********************
                  */}
                  {message.user && message.user !== undefined
                   && message.user.name && message.user.name !== undefined
                   && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.user.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.user.name}
                          src={message.user.name}
                          className={classes.contactAvatar}
                        />
                      </Tooltip>
                    </div>
                  )}


                  
                  {/* 
                    ******************************
                    ** Forward Message Checkbox **
                    ******************************
                  */}
                  {isForwarding
                  && !message.isDeleted
                  && message.mediaType !== "call"
                  && message.mediaType !== "multi_vcard"
                  && message.mediaType !== "poll_creation"
                  && message.mediaType !== "event"
                  && message.mediaType !== "group_invite"
                  && message.mediaType !== "list_creation"
                  && message.mediaType !== "button_creation"
                  && message.mediaType !== "list_response"
                  && message.mediaType !== "button_response"
                  && message.ack !== 0 && message.ack !== 9
                  && !message.isViewOnce
                  && (
                    <div className={classes.forwardMessageCheckbox}>
                      <Checkbox 
                        onClick={() => selectForwardMessage(message.id) }
                        id={`checkbox-${message.id}`}
                      />
                    </div>
                  )}
                </div>

                {/* 
                  **************************
                  ** Interactive Messages **
                  **************************
                */}
                {(message.mediaType !== "list_creation" || message.mediaType !== "button_creation") && (
                  checkInteractiveMessage(message)
                )}

                {renderEndTicket(message)}
              </React.Fragment>
            );
          }



          //  **********************************
          //  ** Message Sent By Me - Sticker **
          //  **********************************
          else if (
            (message.fromMe || (ticketType === 3 && message.userInternalChat !== userId))
            && message.mediaType === "sticker"
          ) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                
                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageRight}`}>
                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={classes.messageRightSticker} onContextMenu={(e) => handleContextMenu(e, message)}>

                    {!message.isBroadcast && !isContactBlocked && !isContactInactive && ticketStatus !== "channel" && (
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      ><ExpandMore /></IconButton>
                    )}

                    {message.isForwarded && (
                      <>
                        <i className={classes.forwardedMessage}>
                          <ReplyOutlined className={classes.horizontalFlip} />{i18n.t("messagesInput.forwardedMessage")}
                        </i>
                        <br />
                      </>
                    )}

                    {message.quotedMsg && (
                      <div
                        className={classes.textContentItem}
                        style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                      >
                        {renderQuotedMessage(message)}
                      </div>
                    )}

                    <img
                      className={clsx(classes.stickerMessage, {[classes.deletedStickerMessage]: message.isDeleted,})}
                      src={message.mediaUrl} alt={message.body} width={225} height={225}
                    />

                    <div 
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted,})}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >

                      <span className={`${classes.timestamp} ${classes.timestampStickerRight}`}>
                        {message.isDeleted && (
                          <Block fontSize="small" className={classes.deletedIcon} />
                        )}

                        {message.isStarred && (
                          <StarOutlined className={classes.timeStampStar} />
                        )}

                        {message.isBroadcast && (
                          <RecordVoiceOverRounded className={classes.timeStampStar} />
                        )}

                        {message.isStatusReply && (
                          <FilterTiltShiftOutlined className={classes.timeStampStar} />
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
                    
                    {(message.contactReaction || message.fromMeReaction) && (
                      <div className={classes.reactionListSticker}>
                        {message.contactReaction && (
                          <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>{message.contactReaction}</span>
                          </Tooltip>
                        )}
  
                        {message.fromMeReaction && (
                          <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>{message.fromMeReaction}</span>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>



                  {/* 
                    **********************
                    ** User Profile Pic **
                    **********************
                  */}
                  {message.user && message.user !== undefined
                   && message.user.name && message.user.name !== undefined
                   && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.user.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.user.name}
                          src={message.user.name}
                          className={classes.contactAvatar}
                        />
                      </Tooltip>
                    </div>
                  )}



                  {/* 
                    ******************************
                    ** Forward Message Checkbox **
                    ******************************
                  */}
                  {isForwarding
                  && !message.isDeleted
                  && message.mediaType !== "call"
                  && message.mediaType !== "multi_vcard"
                  && message.mediaType !== "poll_creation"
                  && message.mediaType !== "event"
                  && message.mediaType !== "group_invite"
                  && message.ack !== 0 && message.ack !== 9
                  && !message.isViewOnce
                  && (
                    <div className={classes.forwardMessageCheckbox}>
                      <Checkbox 
                        onClick={() => selectForwardMessage(message.id) }
                        id={`checkbox-${message.id}`}
                      />
                    </div>
                  )}
                </div>
                {renderEndTicket(message)}
              </React.Fragment>
            );
          }
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.messagesListWrapper}>
      <SocketMessagesList
        dispatch={dispatch}
        setAreThereNewMessagesScroll={setAreThereNewMessagesScroll}
        shouldDisplayScrollButton={shouldDisplayScrollButton}
        scrollToBottom={scrollToBottom}
      />

      <MessageEditModal
        open={messageEditModalOpen}
        onClose={handleCloseMessageEditModal}
        message={selectedMessage}
      />

      <PollVotesModal
        open={pollVotesModalOpen}
        onClose={handleClosePollVotesModal}
        message={selectedPollVotesMessage}
      />

      <MessageEditHistoryModal
        open={messageEditHistoryModalOpen}
        onClose={handleCloseMessageEditHistoryModal}
        message={selectedMessageEditHistory}
      />

      <MessagePropertiesModal
        open={messagePropertiesModalOpen}
        onClose={handleCloseMessagePropertiesModal}
        message={selectedMessageProperties}
      />

      <MessageOptionsMenu
        message={selectedMessage}
        ticketType={ticketType}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
        onUpdate={updateIsForwarding}
        updateIsForwarding={updateIsForwarding}
        isForwarding={isForwarding}
        handleOpenMessageEditModal={handleOpenMessageEditModal}
        handleOpenMessagePropertiesModal={handleOpenMessagePropertiesModal}
        ticketStatus={ticketStatus}
      />

      <PhoneNumberMessageOptionsMenu
        anchorEl={phoneNumberMessageOptionsMenuAnchorEl}
        handleClose={handleClosePhoneNumberMessageOptionsMenu}
        menuOpen={phoneNumberMessageOptionsMenuOpen}
        phoneNumber={selectedPhoneNumberMessage}
        setTabOpen={setTabOpen}
      />

      <ConfirmationModal
        title={selectedContactPrivateChat && `${i18n.t("messagesList.confirmationModal.openPrivateChat.title")} ${selectedContactPrivateChat.name}?`}
        open={openPrivateChatConversationModal}
        onClose={handleClosePrivateChatConversationModal}
        onConfirm={() => handleOpenPrivateChat(selectedContactPrivateChat.id)}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
          <Select
            value={selectedWhatsappId}
            onChange={(e) => setSelectedWhatsappId(e.target.value)}
            label={i18n.t("newTicketModal.connection")}
            fullWidth
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {whatsApps.map((whatsapp) => (
              <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ConfirmationModal>

      
      <div id="messagesList" onDragStart={disableDragEvent} className={classes.messagesList} onScroll={handleScroll}>
        {messagesList.length > 0 ? renderMessages() : []}
        
        {botAnswerDelay && (
          <div className={classes.dotElasticContainer}>
            <div class="dot-elastic"></div>
          </div>
        )}

        <div
          style={{display: shouldDisplayScrollButton ? "block" : "none" }}
          className={classes.scrollBottomContainer}
          onClick={() => handleScrollToBottomButton(true)}
        >
          {areThereNewMessagesScroll && (<span>{i18n.t("messagesInput.scrollToSeeMessages")}</span>)}
          
          <IconButton className={classes.scrollBottomButton}>
            <ExpandMoreOutlined />
          </IconButton>
        </div>
      </div>

      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;